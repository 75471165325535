window.__tnt = window.__tnt || {};
(function(d, tnt) {
    function init() {
        var userAvatars = Array.from(d.querySelectorAll('.user-controls .tn-user-avatar'));
        if (userAvatars.length > 0) {
            if (__tnt.user.loggedIn && __tnt.user.avatar) {
                userAvatars.forEach(function(el) {
                    el.innerHTML = '<img src="' + __tnt.user.avatar + '" class="user-icon" alt="user-icon">';
                });
            } else {
                userAvatars.forEach(function(el) {
                    el.innerHTML = '<i class="fas tnt-user tnt-fw user-icon"></i>';
                });
            }
        }
    }
    if (d.readyState == 'loading') {
        d.addEventListener('DOMContentLoaded', init);
    } else {
        init();
    }
})(document, __tnt);